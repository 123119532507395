<template>
  <div class="markup-tables flex">
    <va-card class="flex xs12 mb-4" v-if="attachment">
      <va-card-title style="font-size: 1.5rem;">
        {{ attachment.name }}
        <va-icon :name="'fa4-download'" class="download-icon" style="padding-left: 1rem; cursor: pointer;" @click="downloadAttachments" v-if="attachment.type" />
      </va-card-title>
    </va-card>

    <div class="row" style="margin-bottom: 1.5rem; margin-left: 0.25rem; margin-right: 0.25rem;" v-if="attachment && attachment.type && attachment.type === 'wav'">
      <va-card class="flex xs12 md4 offset--md1" v-if="attachmentLink">
        <va-card-content>
          <div v-viewer>
            <audio controls>
              <source :src="attachmentLink.attachment" type="audio/wav">
            </audio>
          </div>
        </va-card-content>
      </va-card>
      <va-card class="flex xs12 md4 offset--md1">
        <va-card-content>
          <GMapMap style="width: 100%; height: 280px;" :center="center" :zoom="15" :options="options" ref="gMap">
            <!-- Remove other pointes -->
            <!--
            <GMapMarker
              v-for="stat in markers"
              :icon:"'http://maps.google.com/mapfiles/ms/icons/red-dot.png'"
              :position="{ lat: parseFloat(stat.lat), lng: parseFloat(stat.lon) }"
            />
            -->
            <GMapMarker
              v-for="stat in selectedItems"
              :icon="require('@/assets/google-icon/man.png')"
              :position="{ lat: parseFloat(stat.lat), lng: parseFloat(stat.lon) }"
            />
            <GMapMarker
              v-if="lastPoint"
              :icon="'http://maps.google.com/mapfiles/ms/icons/red-dot.png'"
              :position="{ lat: lastPoint.lat, lng: lastPoint.lng }"
            />
            <GMapInfoWindow
              v-for="stat in infoWindows"
              :closeclick="true"
              :opened="true"
              :position="{ lat: parseFloat(stat.lat), lng: parseFloat(stat.lon) }"
              >
              {{ stat.address }}
            </GMapInfoWindow>
            <GMapMarker v-for="stat in infoWindows"
              :key="stat.uid"
              :position="{ lat: parseFloat(stat.lat), lng: parseFloat(stat.lon) }"
              @click="openMarker(stat.uid)"
            >
              <GMapInfoWindow
                :closeclick="true"
                @closeclick="openMarker(null)"
                :opened="openedMarkerID === stat.uid"
                >
                <div id="contet">
                  <div id="siteNotice"></div>
                  <h1 id="firstHeading" class="firstHeading">Address</h1>
                  <div id="bodyContent">
                    <p>
                      {{ stat.address }}
                    </p>
                  </div>
                </div>
              </GMapInfoWindow>
            </GMapMarker>
            <GMapPolyline :options="directionPath" :path="directionPath.path" v-if="directionPath" />
            <GMapPolyline :options="directionPathCourse" :path="directionPathCourse.path" v-if="directionPathCourse" />
            <GMapPolyline :options="flightPath" :path="flightPath.path" />
          </GMapMap>
        </va-card-content>
      </va-card>
    </div>

    <div class="row" style="margin-bottom: 1.5rem; margin-left: 0.25rem; margin-right: 0.25rem;" v-if="attachment && attachment.type && attachment.type === 'txt'">
      <va-card class="flex xs12 md4 offset--md1" v-if="attachmentLink">
        <va-card-content>
          <div v-viewer>
            <table v-if="questions">
              <tr v-for="(q, i) in questions">
                <td style="padding-right: 1rem; padding-bottom: 1rem;">{{ i }} - </td>
                <td>{{ q }}</td>
              </tr>
            </table>
          </div>
        </va-card-content>
      </va-card>
      <va-card class="flex xs12 md4 offset--md1">
        <va-card-content>
          <GMapMap style="width: 100%; height: 280px;" :center="center" :zoom="15" :options="options" ref="gMap">
            <!-- Remove other pointes -->
            <!--
            <GMapMarker
              v-for="stat in markers"
              :icon:"'http://maps.google.com/mapfiles/ms/icons/red-dot.png'"
              :position="{ lat: parseFloat(stat.lat), lng: parseFloat(stat.lon) }"
            />
            -->
            <GMapMarker
              v-for="stat in selectedItems"
              :icon="require('@/assets/google-icon/man.png')"
              :position="{ lat: parseFloat(stat.lat), lng: parseFloat(stat.lon) }"
            />
            <GMapMarker
              v-if="lastPoint"
              :icon="'http://maps.google.com/mapfiles/ms/icons/red-dot.png'"
              :position="{ lat: lastPoint.lat, lng: lastPoint.lng }"
            />
            <GMapInfoWindow
              v-for="stat in infoWindows"
              :closeclick="true"
              :opened="true"
              :position="{ lat: parseFloat(stat.lat), lng: parseFloat(stat.lon) }"
              >
              {{ stat.address }}
            </GMapInfoWindow>
            <GMapMarker v-for="stat in infoWindows"
              :key="stat.uid"
              :position="{ lat: parseFloat(stat.lat), lng: parseFloat(stat.lon) }"
              @click="openMarker(stat.uid)"
            >
              <GMapInfoWindow
                :closeclick="true"
                @closeclick="openMarker(null)"
                :opened="openedMarkerID === stat.uid"
                >
                <div id="contet">
                  <div id="siteNotice"></div>
                  <h1 id="firstHeading" class="firstHeading">Address</h1>
                  <div id="bodyContent">
                    <p>
                      {{ stat.address }}
                    </p>
                  </div>
                </div>
              </GMapInfoWindow>
            </GMapMarker>
            <GMapPolyline :options="directionPath" :path="directionPath.path" v-if="directionPath" />
            <GMapPolyline :options="directionPathCourse" :path="directionPathCourse.path" v-if="directionPathCourse" />
            <GMapPolyline :options="flightPath" :path="flightPath.path" />
          </GMapMap>
        </va-card-content>
      </va-card>
    </div>

    <div class="modal fade" id="photoModal" tabindex="-1" aria-labelledby="photoModal" aria-hidden="true">
      <div class="modal-dialog modal-fullscreen">
        <div class="modal-content" style="background-color: #000000a6;">
          <div class="modal-header" style="border-bottom: 0;">
            <va-button class="mr-2 mb-2 btn-close" icon="ion_md_close" data-bs-dismiss="modal" />
          </div>
          <div class="modal-body flex-center">
            <img style="height: 100%;" :src="attachmentLink.media" v-if="attachmentLink" />
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="videoModal" tabindex="-1" aria-labelledby="videoModal" aria-hidden="true">
      <div class="modal-dialog modal-fullscreen">
        <div class="modal-content" style="background-color: #000000a6;">
          <div class="modal-header" style="border-bottom: 0;">
            <va-button class="mr-2 mb-2 btn-close" icon="ion_md_close" data-bs-dismiss="modal" />
          </div>
          <div class="modal-body flex-center">
            <video id="attachmentVideo" @play="videoPlayPause(true)" @pause="videoPlayPause(false)" style="width: auto; max-height: 100%;" controls v-if="attachmentLink">
              <source :src="attachmentLink.media_mp4" type="video/mp4">
              <source :src="attachmentLink.media" type="video/mp4">
            </video>
          </div>
        </div>
      </div>
    </div>

    <va-card class="flex xs12 mb-4" v-if="attachment && attachment.note && attachment.note.length > 0">
      <va-card-title style="font-size: 1.5rem;">Note</va-card-title>
      <va-card-content>
        <div class="flex md12">
          {{ attachment.note }}
        </div>
      </va-card-content>
    </va-card>

    <va-card class="flex xs12 mb-4" v-if="attachment && stats">
      <va-card-title style="font-size: 1.5rem;">
        Statistics
        <va-icon :name="'fa4-download'" class="download-icon" style="padding-left: 1rem; cursor: pointer;" @click="downloadCSV" />
      </va-card-title>
      <va-card-content>
        <va-data-table
          :items="stats"
          :columns="columns"
        />
      </va-card-content>
    </va-card>
  </div>
</template>

<script>
import axios from 'axios'
import VueApexCharts from 'vue3-apexcharts'
import { isMobile } from 'mobile-device-detect'

export default {
  components: {
    'apexchart': VueApexCharts
  },
  mounted: async function () {
    this.loader = this.$loading.show()
    try {
      const headers = {
        Authorization: `Bearer ${this.token}`
      }
      // Load attachment
      const attachment = await axios.get(`${process.env.VUE_APP_API_URL}/api/sessions/${this.id}/attachments/${this.attachmentId}`, { headers })
      this.attachment = attachment.data.attachment
      this.stats = this.attachment.stats

      if (this.stats.length > 0) {
        this.stats.forEach((stat) => {
          stat.mic = Math.round(Math.pow(10, stat.mic) * 10000) / 10000
        })

        this.center = {
          lat: this.stats[0] ? parseFloat(this.stats[0].lat) : 0.000000,
          lng: this.stats[0] ? parseFloat(this.stats[0].lon) : 0.000000
        }

        // If attachment is a video attachment, then find the last point
        this.selectedItems = [ this.stats[0] ]

        this.markers = []
        this.infoWindows = []
        const flightPlanCoordinates = []
        const flightPlanCoordinatesTracker = []
        this.stats.forEach((stat) => {
          const newCoordinate = {
            lat: parseFloat(stat.lat),
            lng: parseFloat(stat.lon)
          }
          const newCoordinateTracker = `${stat.lat},${stat.lon}`
          if (flightPlanCoordinatesTracker.indexOf(newCoordinateTracker) < 0) {
            flightPlanCoordinates.push(newCoordinate)
            flightPlanCoordinatesTracker.push(newCoordinateTracker)
          }
          if (stat.address) {
            this.infoWindows.push(stat)
          } else {
            this.markers.push(stat)
          }

          // Fill graph data
          this.series[0].data.push(stat.mic)
          this.series[1].data.push(stat.accelUserX)
          this.series[2].data.push(stat.accelUserY)
          this.series[3].data.push(stat.accelUserZ)
          this.series[4].data.push(stat.gyroX)
          this.series[5].data.push(stat.gyroY)
          this.series[6].data.push(stat.gyroZ)
          this.series[7].data.push(stat.speed)
          this.chartOptions.xaxis.categories.push(stat.timestamp)
        })

        // Find min and max values
        const minMax = []
        minMax.push(Math.min(...this.series[0].data))
        minMax.push(Math.max(...this.series[0].data))
        minMax.push(Math.min(...this.series[1].data))
        minMax.push(Math.max(...this.series[1].data))
        minMax.push(Math.min(...this.series[2].data))
        minMax.push(Math.max(...this.series[2].data))
        minMax.push(Math.min(...this.series[3].data))
        minMax.push(Math.max(...this.series[3].data))
        minMax.push(Math.min(...this.series[4].data))
        minMax.push(Math.max(...this.series[4].data))
        minMax.push(Math.min(...this.series[5].data))
        minMax.push(Math.max(...this.series[5].data))
        minMax.push(Math.min(...this.series[6].data))
        minMax.push(Math.max(...this.series[6].data))
        minMax.push(Math.min(...this.series[7].data))
        minMax.push(Math.max(...this.series[7].data))

        this.videoChartOptions.yaxis.min = Math.min(...minMax)
        this.videoChartOptions.yaxis.max = Math.max(...minMax)

        if (this.infoWindows.length > 0) {
          const tempMarkers = []
          this.markers.forEach((marker) => {
            if (marker.lat !== this.infoWindows[0].lat || marker.lon !== this.infoWindows[0].lon) {
              tempMarkers.push(marker)
            }
          })
          this.markers = tempMarkers
        }

        this.flightPath = {
          path: flightPlanCoordinates,
          geodesic: true,
          strokeColor: '#00FF00',
          strokeOpacity: 1.0,
          strokeWeight: 2,
        }
      }

      // Get attachment links
      const attachmentLink = await axios.get(`${process.env.VUE_APP_API_URL}/api/sessions/${this.id}/attachments/${this.attachmentId}/download`, { headers })
      this.attachmentLink = attachmentLink.data.attachment

      if (this.attachment.type === 'txt') {
        const questionData = await axios.get(this.attachmentLink.attachment)
        this.questions = questionData.data
      }

      this.$refs.gMap.$mapPromise.then(() => {
        this.google = window.google
        this.drawArrow()
      })
    } catch (error) {
      this.$router.push({ name: 'login', query: { redirect: `/pages/sessions/${this.id}/attachments/${this.attachmentId}` } })
    }
    this.loader.hide()

    setTimeout(() => {
      const attachmentVideo = document.getElementById('attachmentVideo')
      if (attachmentVideo) {
        attachmentVideo.addEventListener('timeupdate', this.setMap)
      }
    }, 2000)
  },
  data () {
    return {
      isMobile,
      loader: null,
      token: this.$store.state.token,
      session: {},
      attachmentLink: null,
      attachment: null,
      questions: null,
      id: this.$route.params.id,
      attachmentId: this.$route.params.attachmentId,
      stats: null,
      infoWindows: [],
      markers: [],
      center: null,
      lastPoint: null,
      flightPath: {},
      selectedItems: [],
      mapPoint: null,
      directionPath: null,
      directionPathCourse: null,
      perPage: 5,
      perPageOptions: [5, 10, 25, 50, 100],
      currentPage: 1,
      columns: [
        {
          key: 'lat',
          label: 'Lat',
          headerTitle: 'Lat'
        },
        {
          key: 'lon',
          label: 'Long',
          headerTitle: 'Long'
        },
        {
          key: 'accelUserX',
          label: 'accelUserX',
          headerTitle: 'accelUserX'
        },
        {
          key: 'accelUserY',
          label: 'accelUserY',
          headerTitle: 'accelUserY'
        },
        {
          key: 'accelUserZ',
          label: 'accelUserZ',
          headerTitle: 'accelUserZ'
        },
        {
          key: 'gyroX',
          label: 'gyroX',
          headerTitle: 'gyroX'
        },
        {
          key: 'gyroY',
          label: 'gyroY',
          headerTitle: 'gyroY'
        },
        {
          key: 'gyroZ',
          label: 'gyroZ',
          headerTitle: 'gyroZ'
        },
        {
          key: 'pitch',
          label: 'Pitch',
          headerTitle: 'Pitch'
        },
        {
          key: 'heading',
          label: 'Heading',
          headerTitle: 'Heading'
        },
        {
          key: 'speed',
          label: 'Speed',
          headerTitle: 'Speed'
        },
        {
          key: 'course',
          label: 'Course',
          headerTitle: 'Course'
        },
        {
          key: 'mic',
          label: 'Mic',
          headerTitle: 'Mic'
        },
        {
          key: 'pressure',
          label: 'Pressure',
          headerTitle: 'Pressure'
        },
        {
          key: 'calMagX',
          label: 'CalMagX',
          headerTitle: 'CalMagX'
        },
        {
          key: 'calMagY',
          label: 'CalMagY',
          headerTitle: 'CalMagY'
        },
        {
          key: 'calMagZ',
          label: 'CalMagZ',
          headerTitle: 'CalMagZ'
        },
        {
          key: 'calMagTotal',
          label: 'CalMagTotal',
          headerTitle: 'CalMagTotal'
        },
        {
          key: 'timestamp',
          label: 'Date/Time',
          headerTitle: 'Date/Time',
          sortable: true
        }
      ],
      rows: [],
      google: null,
      series: [
        {
          name: 'MIC',
          data: []
        },
        {
          name: 'ACCELUSERX',
          data: []
        },
        {
          name: 'ACCELUSERY',
          data: []
        },
        {
          name: 'ACCELUSERZ',
          data: []
        },
        {
          name: 'GYROX',
          data: []
        },
        {
          name: 'GYROY',
          data: []
        },
        {
          name: 'GYROZ',
          data: []
        },
        {
          name: 'SPEED',
          data: []
        }
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: true
          },
          foreColor: '#6E8192'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight',
          width: 1
        },
        xaxis: {
          categories: [],
          type: 'datetime'
        },
        colors: ['#0000FF', '#5F0F40', '#00B577', '#FF0025', '#4F399A', '#008DFB', '#006E48', '#888f01']
      },
      videoSeries: [
        {
          name: 'MIC',
          data: []
        },
        {
          name: 'ACCELUSERX',
          data: []
        },
        {
          name: 'ACCELUSERY',
          data: []
        },
        {
          name: 'ACCELUSERZ',
          data: []
        },
        {
          name: 'GYROX',
          data: []
        },
        {
          name: 'GYROY',
          data: []
        },
        {
          name: 'GYROZ',
          data: []
        },
        {
          name: 'SPEED',
          data: []
        }
      ],
      videoChartOptions: {
        chart: {
          height: 350,
          type: 'line',
          animations: {
            enabled: true,
            easing: 'linear',
            dynamicAnimation: {
              speed: 1000
            }
          },
          zoom: {
            enabled: true
          },
          foreColor: '#6E8192'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight',
          width: 1
        },
        xaxis: {
          categories: [],
          type: 'datetime',
          range: 2000
        },
        yaxis: {},
        colors: ['#0000FF', '#5F0F40', '#00D68D', '#FF0025', '#4F399A', '#1500FB', '#006E48', '#888f01']
      },
      videoChart: false,
      playChart: false,
      playPauseChartTimeout: null,
      openedMarkerID: null,
      options: {
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        styles: [
          {
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#1d2c4d"
              }
            ]
          },
          {
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#8ec3b9"
              }
            ]
          },
          {
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "color": "#1a3646"
              }
            ]
          },
          {
            "featureType": "administrative.country",
            "elementType": "geometry.stroke",
            "stylers": [
              {
                "color": "#4b6878"
              }
            ]
          },
          {
            "featureType": "administrative.land_parcel",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#64779e"
              }
            ]
          },
          {
            "featureType": "administrative.province",
            "elementType": "geometry.stroke",
            "stylers": [
              {
                "color": "#4b6878"
              }
            ]
          },
          {
            "featureType": "landscape.man_made",
            "elementType": "geometry.stroke",
            "stylers": [
              {
                "color": "#334e87"
              }
            ]
          },
          {
            "featureType": "landscape.natural",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#023e58"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#283d6a"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#6f9ba5"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "color": "#1d2c4d"
              }
            ]
          },
          {
            "featureType": "poi.park",
            "elementType": "geometry.fill",
            "stylers": [
              {
                "color": "#023e58"
              }
            ]
          },
          {
            "featureType": "poi.park",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#3C7680"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#304a7d"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#98a5be"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "color": "#1d2c4d"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#2c6675"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "geometry.stroke",
            "stylers": [
              {
                "color": "#255763"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#b0d5ce"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "color": "#023e58"
              }
            ]
          },
          {
            "featureType": "transit",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#98a5be"
              }
            ]
          },
          {
            "featureType": "transit",
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "color": "#1d2c4d"
              }
            ]
          },
          {
            "featureType": "transit.line",
            "elementType": "geometry.fill",
            "stylers": [
              {
                "color": "#283d6a"
              }
            ]
          },
          {
            "featureType": "transit.station",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#3a4762"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#0e1626"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#4e6d70"
              }
            ]
          }
        ]
      },
    }
  },
  computed: {
    pages () {
      return (this.perPage && this.perPage !== 0)
        ? Math.ceil(this.stats.length / this.perPage)
        : this.stats.length
    }
  },
  watch: {
    selectedItems () {
      this.drawArrow()
      this.setVideoTime()
    }
  },
  methods: {
    downloadAttachments: async function () {
      this.$vaToast.init({
        message: 'Downloading Attachments. Please wait.',
        iconClass: 'fa-star-o',
        position: 'bottom-right',
        duration: 6000,
        fullWidth: false,
        color: 'primary',
      })
      try {
        const headers = {
          Authorization: `Bearer ${this.token}`
        }
        // Get attachment links
        const attachmentLink = await axios.get(`${process.env.VUE_APP_API_URL}/api/sessions/${this.id}/attachments/${this.attachmentId}/download`, { headers })

        axios({
            url: attachmentLink.data.attachment.attachment,
            method: 'GET',
            responseType: 'arraybuffer',
          }).then((res) => {
            const blob = new Blob([res.data], {
              type: this.attachment.type === 'txt' ? 'text/plain' : 'audio/wav'
            })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `${this.attachment.name.split('.')[0]}.${this.attachment.type}`
            link.click()
          })
      } catch (error) {
        this.$router.push({ name: 'login', query: { redirect: `/pages/sessions/${this.id}/attachments/${this.attachmentId}` } })
      }
    },
    downloadCSV () {
      this.rows = [
        ['lat', 'lon', 'accelUserX', 'accelUserY', 'accelUserZ', 'gyroX', 'gyroY', 'gyroZ', 'pitch', 'heading', 'speed', 'course', 'mic', 'pressure', 'calMagX', 'calMagY', 'calMagZ', 'calMagTotal', 'timestamp']
      ]
      this.stats.forEach((stat) => {
        this.rows.push([
          stat.lat, stat.lon, stat.accelUserX, stat.accelUserY, stat.accelUserZ, stat.gyroX, stat.gyroY, stat.gyroZ, stat.pitch, stat.heading, stat.speed, stat.course, stat.mic, stat.pressure, stat.calMagX, stat.calMagY, stat.calMagZ, stat.calMagTotal, stat.timestamp
        ])
      })

      const csvContent = 'data:text/csv;charset=utf-8,' + this.rows.map(e => e.join(',')).join('\n')

      const encodedUri = encodeURI(csvContent)

      const link = document.createElement('a')
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', `${this.attachment.name.split('.')[0]}.csv`)
      document.body.appendChild(link)

      link.click()
    },
    drawArrow: async function () {
      if (this.selectedItems.length > 0) {
        const pointA = new this.google.maps.LatLng(parseFloat(this.selectedItems[0].lat), parseFloat(this.selectedItems[0].lon))
        const distance = 20 // in meters
        let bearing = parseFloat(this.selectedItems[0].heading)
        let pointB = this.google.maps.geometry.spherical.computeOffset(pointA, distance, bearing)

        const lineSymbol = {
          path: this.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        }

        this.directionPath = {
          path: [
            {
              lat: pointA.lat(),
              lng: pointA.lng()
            },
            {
              lat: pointB.lat(),
              lng: pointB.lng()
            }
          ],
          icons: [
            {
              icon: lineSymbol,
              offset: '100%',
            },
          ],
          geodesic: true,
          strokeColor: '#FA86C4',
          strokeOpacity: 1.0,
          strokeWeight: 2,
        }

        bearing = parseFloat(this.selectedItems[0].course)
        pointB = this.google.maps.geometry.spherical.computeOffset(pointA, distance, bearing)

        this.directionPathCourse = {
          path: [
            {
              lat: pointA.lat(),
              lng: pointA.lng()
            },
            {
              lat: pointB.lat(),
              lng: pointB.lng()
            }
          ],
          icons: [
            {
              icon: lineSymbol,
              offset: '100%',
            },
          ],
          geodesic: true,
          strokeColor: '#006721',
          strokeOpacity: 1.0,
          strokeWeight: 2,
        }
      }
      else {
        this.directionPath = null
        this.directionPathCourse = null
      }
    },
    setVideoTime: function () {
      if (this.selectedItems.length > 0 && this.attachment.type !== 'wav' && this.attachment.type !== 'txt') {
        const startTime = new Date(this.stats[0].timestamp)
        const endTime = new Date(this.selectedItems[0].timestamp)
        const timeDifference = (endTime.getTime() - startTime.getTime()) / 1000
        document.getElementById('attachmentVideo').currentTime = timeDifference
      }
    },
    setMap: function () {
      const startTime = new Date(this.stats[0].timestamp)
      const attachmentVideo = document.getElementById('attachmentVideo')
      const endTime = startTime.getTime() + (attachmentVideo.currentTime * 1000)
      const date = new Date(endTime)

      for (let i = 0; i < this.stats.length; i++) {
        if (date < new Date(this.stats[i].timestamp)) {
          if (!this.mapPoint || this.mapPoint.lat !== this.stats[i].lat || this.mapPoint.lon !== this.stats[i].lon) {
            this.mapPoint = this.stats[i]
            const pointA = new this.google.maps.LatLng(parseFloat(this.stats[i].lat), parseFloat(this.stats[i].lon))
            const distance = 20 // in meters
            let bearing = parseFloat(this.stats[i].heading)
            let pointB = this.google.maps.geometry.spherical.computeOffset(pointA, distance, bearing)

            const lineSymbol = {
              path: this.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            }

            this.directionPath = {
              path: [
                {
                  lat: pointA.lat(),
                  lng: pointA.lng()
                },
                {
                  lat: pointB.lat(),
                  lng: pointB.lng()
                }
              ],
              icons: [
                {
                  icon: lineSymbol,
                  offset: '100%',
                },
              ],
              geodesic: true,
              strokeColor: '#FA86C4',
              strokeOpacity: 1.0,
              strokeWeight: 2,
            }

            bearing = parseFloat(this.stats[i].course)
            pointB = this.google.maps.geometry.spherical.computeOffset(pointA, distance, bearing)

            this.directionPathCourse = {
              path: [
                {
                  lat: pointA.lat(),
                  lng: pointA.lng()
                },
                {
                  lat: pointB.lat(),
                  lng: pointB.lng()
                }
              ],
              icons: [
                {
                  icon: lineSymbol,
                  offset: '100%',
                },
              ],
              geodesic: true,
              strokeColor: '#006721',
              strokeOpacity: 1.0,
              strokeWeight: 2,
            }
          }

          this.videoSeries[0].data.push(this.stats[i].mic)
          this.videoSeries[1].data.push(this.stats[i].accelUserX)
          this.videoSeries[2].data.push(this.stats[i].accelUserY)
          this.videoSeries[3].data.push(this.stats[i].accelUserZ)
          this.videoSeries[4].data.push(this.stats[i].gyroX)
          this.videoSeries[5].data.push(this.stats[i].gyroY)
          this.videoSeries[6].data.push(this.stats[i].gyroZ)
          this.videoSeries[7].data.push(this.stats[i].speed)
          this.videoChartOptions.xaxis.categories.push(this.stats[i].timestamp)

          break
        }
      }
    },
    videoPlayPause (play) {
      if (play) {
        this.videoSeries[0].data = []
        this.videoSeries[1].data = []
        this.videoSeries[2].data = []
        this.videoSeries[3].data = []
        this.videoSeries[4].data = []
        this.videoSeries[5].data = []
        this.videoSeries[6].data = []
        this.videoSeries[7].data = []
        this.videoChartOptions.xaxis.categories = []

        this.videoChart = true
      } else {
        this.videoChart = false
      }
    },
    markerClickHandler (event, chartContext, { seriesIndex, dataPointIndex, config }) {
      // Set Map point
      this.mapPoint = this.stats[dataPointIndex]
      const pointA = new this.google.maps.LatLng(parseFloat(this.stats[dataPointIndex].lat), parseFloat(this.stats[dataPointIndex].lon))
      const distance = 20 // in meters
      let bearing = parseFloat(this.stats[dataPointIndex].heading)
      let pointB = this.google.maps.geometry.spherical.computeOffset(pointA, distance, bearing)

      const lineSymbol = {
        path: this.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
      }

      this.directionPath = {
        path: [
          {
            lat: pointA.lat(),
            lng: pointA.lng()
          },
          {
            lat: pointB.lat(),
            lng: pointB.lng()
          }
        ],
        icons: [
          {
            icon: lineSymbol,
            offset: '100%',
          },
        ],
        geodesic: true,
        strokeColor: '#FA86C4',
        strokeOpacity: 1.0,
        strokeWeight: 2,
      }

      bearing = parseFloat(this.stats[dataPointIndex].course)
      pointB = this.google.maps.geometry.spherical.computeOffset(pointA, distance, bearing)

      this.directionPathCourse = {
        path: [
          {
            lat: pointA.lat(),
            lng: pointA.lng()
          },
          {
            lat: pointB.lat(),
            lng: pointB.lng()
          }
        ],
        icons: [
          {
            icon: lineSymbol,
            offset: '100%',
          },
        ],
        geodesic: true,
        strokeColor: '#006721',
        strokeOpacity: 1.0,
        strokeWeight: 2,
      }

      // Set Video time
      const attachmentVideo = document.getElementById('attachmentVideo')
      if (attachmentVideo) {
        const startTime = new Date(this.stats[0].timestamp)
        const endTime = new Date(this.stats[dataPointIndex].timestamp)
        const timeDifference = (endTime.getTime() - startTime.getTime()) / 1000
        attachmentVideo.currentTime = timeDifference
      }
    },
    playPauseChart (play) {
      if (play) {
        this.playChart = true

        let i = 0

        this.playPauseChartTimeout = setInterval(() => {
          if (!this.mapPoint || this.mapPoint.lat !== this.stats[i].lat || this.mapPoint.lon !== this.stats[i].lon) {
            this.mapPoint = this.stats[i]
            const pointA = new this.google.maps.LatLng(parseFloat(this.stats[i].lat), parseFloat(this.stats[i].lon))
            const distance = 20 // in meters
            let bearing = parseFloat(this.stats[i].heading)
            let pointB = this.google.maps.geometry.spherical.computeOffset(pointA, distance, bearing)

            const lineSymbol = {
              path: this.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            }

            this.directionPath = {
              path: [
                {
                  lat: pointA.lat(),
                  lng: pointA.lng()
                },
                {
                  lat: pointB.lat(),
                  lng: pointB.lng()
                }
              ],
              icons: [
                {
                  icon: lineSymbol,
                  offset: '100%',
                },
              ],
              geodesic: true,
              strokeColor: '#FA86C4',
              strokeOpacity: 1.0,
              strokeWeight: 2,
            }

            bearing = parseFloat(this.stats[i].course)
            pointB = this.google.maps.geometry.spherical.computeOffset(pointA, distance, bearing)

            this.directionPathCourse = {
              path: [
                {
                  lat: pointA.lat(),
                  lng: pointA.lng()
                },
                {
                  lat: pointB.lat(),
                  lng: pointB.lng()
                }
              ],
              icons: [
                {
                  icon: lineSymbol,
                  offset: '100%',
                },
              ],
              geodesic: true,
              strokeColor: '#006721',
              strokeOpacity: 1.0,
              strokeWeight: 2,
            }
          }

          this.videoSeries[0].data.push(this.stats[i].mic)
          this.videoSeries[1].data.push(this.stats[i].accelUserX)
          this.videoSeries[2].data.push(this.stats[i].accelUserY)
          this.videoSeries[3].data.push(this.stats[i].accelUserZ)
          this.videoSeries[4].data.push(this.stats[i].gyroX)
          this.videoSeries[5].data.push(this.stats[i].gyroY)
          this.videoSeries[6].data.push(this.stats[i].gyroZ)
          this.videoSeries[7].data.push(this.stats[i].speed)
          this.videoChartOptions.xaxis.categories.push(this.stats[i].timestamp)

          i = i + 1

          if (i >= this.stats.length) {
            if (this.playPauseChartTimeout) {
              clearInterval(this.playPauseChartTimeout)
              this.playChart = false

              this.videoSeries[0].data = []
              this.videoSeries[1].data = []
              this.videoSeries[2].data = []
              this.videoSeries[3].data = []
              this.videoSeries[4].data = []
              this.videoSeries[5].data = []
              this.videoSeries[6].data = []
              this.videoSeries[7].data = []
              this.videoChartOptions.xaxis.categories = []
            }
          }
        }, 34)

      } else {
        if (this.playPauseChartTimeout) {
          clearInterval(this.playPauseChartTimeout)
        }

        this.playChart = false

        this.videoSeries[0].data = []
        this.videoSeries[1].data = []
        this.videoSeries[2].data = []
        this.videoSeries[3].data = []
        this.videoSeries[4].data = []
        this.videoSeries[5].data = []
        this.videoSeries[6].data = []
        this.videoSeries[7].data = []
        this.videoChartOptions.xaxis.categories = []
      }
    },
    openMarker(id) {
      this.openedMarkerID = id
    }
  },
}
</script>

<style lang="scss">
  .markup-tables {
    .table-wrapper {
      overflow: auto;
    }

    .va-table {
      width: 100%;
    }
  }

  .table-example--pagination {
    text-align: right;
    text-align: -webkit-right;
    padding-top: 1rem;
  }

  .download-icon:hover {
    color: var(--va-primary);
  }

  video {
    width: 80%;
    height: 80%;
    object-fit: cover;
    position: absolute;
  }

  .gm-fullscreen-control {
    background-color: var(--va-white) !important;
  }

  .gmnoprint > .gmnoprint > div {
    background-color: var(--va-white) !important;
  }

  .va-data-table {
    --va-data-table-thead-color: var(--va-primary);
  }

  .va-pagination__input {
    background-color: var(--va-white);
  }

  .va-alert {
    background-color: var(--va-white) !important;;
  }

  .vue-map > div > div > div > div > div > div > div > div > div > div > div {
    color: var(--va-pure-dark) !important;
  }

  .gm-style-mtc > button {
    background-color: var(--va-white) !important;
    color: var(--va-dark) !important;
  }
</style>
